import React from 'react';

const Profile = React.lazy(() => import('../pages/Profile'));

const routes = [
  {
    path: '/admin-usuario',
    name: 'Profile',
    Component: Profile,
  },
];

export default routes;
