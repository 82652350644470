/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React, { Suspense, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Col, Row, Spinner } from 'reactstrap';

import saflhino from './app/assets/sound/safl.mp3';
import { useAuth } from './app/hooks/auth';
import ManagerProvider from './app/hooks/manager';
import AppAdminRoutes from './app/routes/app.admin.routes';
import AppRoutes from './app/routes/app.routes';
import AuthRoutes from './app/routes/auth.routes';

import 'react-pro-sidebar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/vendor/fontawesome-free-6.2.0-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/bootstrap9.css';
import './app/assets/css/custom.css';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

function App() {
  const saflhinoRef = useRef();
  const { isAuthenticated, isAuthenticatedAdmin } = useAuth();

  useEffect(() => {
    try {
      saflhinoRef.current
        .play()
        .then(() => console.log('run perfectly'))
        .catch((error) => console.log('play promisse fails', error));
    } catch (error) {
      console.log('try-catch fails');
    }
  }, []);

  return (
    <Router>
      <Suspense fallback={loading}>
        {isAuthenticatedAdmin ? (
          <ManagerProvider>
            <AppAdminRoutes />
          </ManagerProvider>
        ) : isAuthenticated ? (
          <ManagerProvider>
            <AppRoutes />
          </ManagerProvider>
        ) : (
          <>
            <Row className="my-3">
              <Col>
                <div className="d-flex justify-content-center">
                  <audio
                    ref={saflhinoRef}
                    src={saflhino}
                    autoPlay
                    loop
                    controls
                  />
                </div>
              </Col>
            </Row>
            <AuthRoutes />
          </>
        )}
      </Suspense>
    </Router>
  );
}

export default App;
