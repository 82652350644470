import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));
const PresidenteAgenda = React.lazy(() => import('../pages/PresidenteAgenda'));
const PresidentePalavra = React.lazy(() => import('../pages/PresidentePalavra'));
const FormularioProjetoEmenda = React.lazy(() => import('../pages/FormularioProjetoEmenda'));
const AniversarioLojasList = React.lazy(() => import('../pages/AniversarioLojasList'));
const NoticiasList = React.lazy(() => import('../pages/NoticiasList'));
const Noticia = React.lazy(() => import('../pages/Noticia'));
const FotoGaleriaList = React.lazy(() => import('../pages/FotoGaleriasList'));
const VideoList = React.lazy(() => import('../pages/VideoList'));
const ExPresidenteList = React.lazy(() => import('../pages/ExPresidenteList'));
const InformativosList = React.lazy(() => import('../pages/InformativosList'));
const InformativosImportantesList = React.lazy(() => import('../pages/InformativosImportantesList'));
const NotaDePesarsList = React.lazy(() => import('../pages/NotaDePesarsList'));
const EnquetesList = React.lazy(() => import('../pages/EnquetesList'));
const ComunicadosList = React.lazy(() => import('../pages/ComunicadosList'));

export default [
  { path: '/', Component: Home },
  { path: '/loginAdmin', Component: LoginAdmin },
  { path: '/presidente-agendas', Component: PresidenteAgenda },
  { path: '/presidente-palavras', Component: PresidentePalavra },
  { path: '/formulario-projeto-emenda', Component: FormularioProjetoEmenda },
  { path: '/aniversario-de-lojas', Component: AniversarioLojasList },
  { path: '/noticias', Component: NoticiasList },
  { path: '/noticia/:id', Component: Noticia },
  { path: '/galeria-de-fotos', Component: FotoGaleriaList },
  { path: '/galeria-de-videos', Component: VideoList },
  { path: '/ex-presidentes', Component: ExPresidenteList },
  { path: '/informativos', Component: InformativosList },
  { path: '/informativos-importantes', Component: InformativosImportantesList },
  { path: '/notas-de-pesar', Component: NotaDePesarsList },
  { path: '/enquetes', Component: EnquetesList },
  { path: '/comunicados', Component: ComunicadosList },
  { path: '*', Component: Home },
];
