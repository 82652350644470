import React from 'react';

const Dashboard = React.lazy(() => import('../pages/DashboardAdmin'));
const Admins = React.lazy(() => import('../pages/Admins'));
// const Usuarios = React.lazy(() => import('../pages/Users'));
const Sliders = React.lazy(() => import('../pages/Sliders'));
const SessaoDinamicas = React.lazy(() => import('../pages/SessaoDinamicas'));
const Presidentes = React.lazy(() => import('../pages/Presidentes'));
const PresidenteAgendas = React.lazy(() => import('../pages/PresidenteAgendas'));
const PresidentePalavras = React.lazy(() => import('../pages/PresidentePalavras'));
const Informativos = React.lazy(() => import('../pages/Informativos'));
const InformativosImportantes = React.lazy(() => import('../pages/InformativosImportantes'));
const AniversarioLojas = React.lazy(() => import('../pages/AniversarioLojas'));
const Noticias = React.lazy(() => import('../pages/Noticias'));
const Enquetes = React.lazy(() => import('../pages/Enquetes'));
const Comunicados = React.lazy(() => import('../pages/Comunicados'));
const ReuniaoLinks = React.lazy(() => import('../pages/ReuniaoLinks'));
const Empossados = React.lazy(() => import('../pages/Empossados'));
const NotaDePesars = React.lazy(() => import('../pages/NotaDePesars'));
const LojaHomologadas = React.lazy(() => import('../pages/LojaHomologadas'));
const FotoGalerias = React.lazy(() => import('../pages/FotoGalerias'));
const Videos = React.lazy(() => import('../pages/Videos'));

const routes = [
  {
    path: '/admin/',
    Component: Dashboard,
  },
  {
    path: '/admin/administradores',
    Component: Admins,
  },
  {
    path: '/admin/sliders',
    Component: Sliders,
  },
  {
    path: '/admin/sessoes-dinamicas',
    Component: SessaoDinamicas,
  },
  {
    path: '/admin/presidentes',
    Component: Presidentes,
  },
  {
    path: '/admin/presidente-agendas',
    Component: PresidenteAgendas,
  },
  {
    path: '/admin/presidente-palavras',
    Component: PresidentePalavras,
  },
  {
    path: '/admin/informativos',
    Component: Informativos,
  },
  {
    path: '/admin/informativos-importantes',
    Component: InformativosImportantes,
  },
  {
    path: '/admin/aniversario-de-lojas',
    Component: AniversarioLojas,
  },
  {
    path: '/admin/noticias',
    Component: Noticias,
  },
  {
    path: '/admin/enquetes',
    Component: Enquetes,
  },
  {
    path: '/admin/comunicados',
    Component: Comunicados,
  },
  {
    path: '/admin/reunioes-zoom',
    Component: ReuniaoLinks,
  },
  {
    path: '/admin/empossados',
    Component: Empossados,
  },
  {
    path: '/admin/notas-de-pesares',
    Component: NotaDePesars,
  },
  {
    path: '/admin/lojas-homologadas',
    Component: LojaHomologadas,
  },
  {
    path: '/admin/galeria-de-fotos',
    Component: FotoGalerias,
  },
  {
    path: '/admin/galeria-de-videos',
    Component: Videos,
  },
];

export default routes;
